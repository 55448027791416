import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Hero from '../components/Hero'

const CustomerStoryTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  website,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <p>{description}</p>
            <PostContent content={content} />
            <br></br>
            <h4>Website</h4>
            <Link to={website}>{website}</Link>
            <br></br>
            <br></br>
            <h4>Tags</h4>
            <div className="tags">
              {tags.map(tag => (
                <Link
                  key="{tag}"
                  className="tag is-small"
                  to={`/tags/${kebabCase(tag)}/`}
                >
                  {tag}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

CustomerStoryTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  website: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const CustomerStory = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Hero
        title="Erfolgsgeschichten"
        subtitle="Wir sind zufrieden, wenn unsere Kunden zufrieden sind!"
        img={data.hero.childImageSharp}
      />
      <CustomerStoryTemplate
        content={post.html}
        contentComponent={HTMLContent}
        //description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        website={post.frontmatter.website}
      />
    </Layout>
  )
}

CustomerStory.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default CustomerStory

export const pageQuery = graphql`
  query CustomerStoryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        website
      }
    }
    hero: file(relativePath: { eq: "customer.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
